import * as firebase from 'firebase/app';
import 'firebase/messaging';

import { Subject } from 'rxjs';

const messaging = firebase.messaging();
const messaging$ = new Subject();
const messagingOb$ = messaging$.asObservable();

messaging.usePublicVapidKey(process.env.VUE_APP_FIREBASE_MSG_VAPID_KEY);
setup();

async function setup() {
  let token = await requestToken();
  console.log('token', token);

  messaging.onMessage(message => {
    messaging$.next(message);
  });
}

async function requestToken() {
  let permission = await Notification.requestPermission();
  if (permission !== 'granted') {
    return;
  }

  let token = await messaging.getToken();
  return token;
}

export default messagingOb$;